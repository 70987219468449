import './../css/gallery.css';
import axios from 'axios';
import React from 'react';
// @ts-ignore
import { Fancybox } from "@fancyapps/ui/dist/fancybox.esm.js";
import "@fancyapps/ui/dist/fancybox.css";

interface Props {
    locale: string,
    setPageLoaded: Function
}

interface State {
    content: any
    active: string
}

class Gallery extends React.Component<Props, State> {
 
    state: State = {
        content: null,
        active: 'stills'
    }

    componentDidMount() {
        this.getContent(this.props.locale);

        Fancybox.bind("[data-fancybox]", {
            Thumbs: {
                autoStart: false,
            },
        });

        return () => {
            Fancybox.destroy();
        };
    }

    getContent = (locale: string) => {
        axios
            .get(process.env.REACT_APP_STRAPI_URL + '/api/gallery?populate=*&locale=' + locale)
            .then((response: any) => { 
                this.setState({
                    content: response.data.data.attributes
                }, () => { this.props.setPageLoaded("gallery") });
            })
            .catch((error: any) => 
            {
                if(locale !== 'en' && error.response && error.response.status === 404)
                {
                    console.log('Error: Could not find localized content > loading English');
                    this.getContent('en');
                }
                else
                {
                    console.log(error.message);
                }
            });
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        if(prevProps.locale !== this.props.locale)
        {
            this.getContent(this.props.locale);
        }
    }

    switchGallery = (gallery: string) => {
        this.setState({
            active: gallery
        });
    };

    render() {
        return (
            <div id="gallery" className="page black"> 
                {this.state.content && 
                    <div className="content">
                        <h1>{this.state.content.title}</h1>
                        <div className='gallery-menu'>
                            <button className={`gallery-button ${this.state.active === 'stills' ? 'active' : ''}`} onClick={() => this.switchGallery('stills')}>{this.state.content.stillsTitle}</button>
                            <button className={`gallery-button ${this.state.active === 'photos' ? 'active' : ''}`} onClick={() => this.switchGallery('photos')}>{this.state.content.photosTitle}</button>
                        </div>
                        <div className={`gallery-flex ${this.state.active === 'stills' ? 'active' : ''}`}>
                            {this.state.content.stillsGallery.data.map((still: any) => 
                                <div key={still.id}>
                                    <a data-fancybox="stills" href={process.env.REACT_APP_STRAPI_URL + (still.attributes.formats.large ? still.attributes.formats.large.url : still.attributes.url)}>
                                        <img src={process.env.REACT_APP_STRAPI_URL + still.attributes.formats.small.url} alt="" />
                                    </a>
                                </div>
                            )}
                        </div>
                        <div className={`gallery-flex ${this.state.active === 'photos' ? 'active' : ''}`}>
                            {this.state.content.photosGallery.data.map((photo: any) => 
                                <div key={photo.id}>
                                    <a data-fancybox="photos" href={process.env.REACT_APP_STRAPI_URL + (photo.attributes.formats.large ? photo.attributes.formats.large.url : photo.attributes.url)}>
                                        <img src={process.env.REACT_APP_STRAPI_URL + photo.attributes.formats.small.url} alt="" />
                                    </a>
                                </div>
                            )}
                        </div>
                    </div>
                }
            </div>
        )
    }
}

export default Gallery;