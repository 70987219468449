import './../css/home.css';
import axios from 'axios';
import React from 'react';
import titleImage from './../img/iraq-title.svg';
import laurelsImage from './../img/laurels.png';
import { Helmet } from 'react-helmet-async';

interface Props {
    locale: string,
    setPageLoaded: Function
}

interface State {
    content: any,
    backgroundStyle: any
}

class Home extends React.Component<Props, State> {
 
    state: State = {
        content: null,
        backgroundStyle: null
    }

    componentDidMount() {
        this.getContent(this.props.locale);
    }

    getContent = (locale: string) => {
        axios
            .get(process.env.REACT_APP_STRAPI_URL + '/api/home?populate=*&locale=' + locale)
            .then((response: any) => { 
                this.setState({
                    content: response.data.data.attributes,
                    backgroundStyle: { 
                        backgroundImage: 'url(' + process.env.REACT_APP_STRAPI_URL + response.data.data.attributes.backgroundImage.data.attributes.url + ')',
                        backgroundPosition: 'center center',
                        backgroundSize: 'cover'
                    }
                }, () => { this.props.setPageLoaded("home") });
            })
            .catch((error: any) => 
            {
                if(locale !== 'en' && error.response && error.response.status === 404)
                {
                    console.log('Error: Could not find localized content > loading English');
                    this.getContent('en');
                }
                else
                {
                    console.log(error.message);
                }
            });
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        if(prevProps.locale !== this.props.locale)
        {
            this.getContent(this.props.locale);
        }
    }

    render() {
        return (

            <div id="home" className="page" style={this.state.backgroundStyle}> 
                {this.state.content &&
                    <Helmet>
                        <title>{this.state.content.metaTitle}</title>
                        <meta property="og:title" content={this.state.content.metaTitle} />
                        <meta name="description" content={this.state.content.metaDescription} />
                        <meta property="og:description" content={this.state.content.metaDescription} />
                        <meta property="og:image" content={process.env.REACT_APP_STRAPI_URL + this.state.content.metaImage.data.attributes.url} />
                    </Helmet>
                }
                <div className="content">
                    <div><img id="logo" alt="Iraq's Invisible Beauty" src={titleImage} /></div>
                    {this.state.content && 
                        <div>
                            <div className="creditTitle">{this.state.content.creditTitle}</div>
                            <div className="creditName">{this.state.content.creditName}</div>
                            <div><img id="laurels" alt="laurels" src={laurelsImage} /></div>
                            <div><img id="logos" alt="sponsors" src={process.env.REACT_APP_STRAPI_URL + this.state.content.logos.data.attributes.url} /></div>
                        </div>
                    }
                </div>
            </div>
        )
    }
}

export default Home;