import './../css/contact.css';
import axios from 'axios';
import React from 'react';
import ReactMarkdown from 'react-markdown';

interface Props {
    locale: string,
    setPageLoaded: Function
}

interface State {
    content: any
}

class Contact extends React.Component<Props, State> {
 
    state: State = {
        content: null
    }

    componentDidMount() {
        this.getContent(this.props.locale);
    }

    getContent = (locale: string) => {
        axios
            .get(process.env.REACT_APP_STRAPI_URL + '/api/contact?populate=*&locale=' + locale)
            .then((response: any) => { 
                this.setState({
                    content: response.data.data.attributes
                }, () => { this.props.setPageLoaded("contact") });
            })
            .catch((error: any) => 
            {
                if(locale !== 'en' && error.response && error.response.status === 404)
                {
                    console.log('Error: Could not find localized content > loading English');
                    this.getContent('en');
                }
                else
                {
                    console.log(error.message);
                }
            });
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        if(prevProps.locale !== this.props.locale)
        {
            this.getContent(this.props.locale);
        }
    }

    render() {
        return (
            <div id="contact" className="page black"> 
                {this.state.content && 
                    <div className="content">
                            <h1>{this.state.content.title}</h1>
                            <div id="contact-columns">
                                <div className="contact-column">
                                    <h2>{this.state.content.infoTitle}</h2>
                                    <div className="contact-text"><ReactMarkdown>{this.state.content.infoText}</ReactMarkdown></div>
                                </div>
                                <div className="contact-column">
                                    <h2>{this.state.content.presskitTitle}</h2>
                                    <div className="contact-text"><ReactMarkdown>{this.state.content.presskitText}</ReactMarkdown></div>
                                    {this.state.content.presskitDownload.data &&
                                        <a className="button yellow" href={process.env.REACT_APP_STRAPI_URL + this.state.content.presskitDownload.data.attributes.url}>{this.state.content.presskitButtonLabel}</a>
                                    }
                                </div>
                                <div className="contact-column">
                                    <h2>{this.state.content.newsletterTitle}</h2>
                                    <div className="contact-text"><ReactMarkdown>{this.state.content.newsletterText}</ReactMarkdown></div>
                                    <a className="button yellow" rel="noreferrer" target='_blank' href={this.state.content.newsletterButtonUrl}>{this.state.content.newsletterButtonLabel}</a>
                                </div>
                            </div>
                        
                    </div>
                }
            </div>
        )
    }
}

export default Contact;