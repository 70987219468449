import './../css/trailer.css';
import axios from 'axios';
import React from 'react';
import ReactMarkdown from 'react-markdown';

interface Props {
    locale: string,
    setPageLoaded: Function
}

interface State {
    content: any
}

class Trailer extends React.Component<Props, State> {
 
    state: State = {
        content: null
    }

    componentDidMount() {
        this.getContent(this.props.locale);
    }

    getContent = (locale: string) => {
        axios
            .get(process.env.REACT_APP_STRAPI_URL + '/api/trailer?populate=*&locale=' + locale)
            .then((response: any) => { 
                this.setState({
                    content: response.data.data.attributes
                }, () => { this.props.setPageLoaded("trailer") });
            })
            .catch((error: any) => 
            {
                if(locale !== 'en' && error.response && error.response.status === 404)
                {
                    console.log('Error: Could not find localized content > loading English');
                    this.getContent('en');
                }
                else
                {
                    console.log(error.message);
                }
            });
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        if(prevProps.locale !== this.props.locale)
        {
            this.getContent(this.props.locale);
        }
    }

    render() {
        return ( 
            <div id="trailer" className="page yellow"> 
                <div className="content">
                    {this.state.content && 
                        <div>
                            <h1>
                                {this.state.content.title}
                            </h1>
                            <div className="trailer">
                                <iframe title="trailer" src={"https://player.vimeo.com/video/" + this.state.content.vimeoID + "?color=ffffff&title=0&byline=0&portrait=0"} frameBorder="0" allowFullScreen></iframe>
                            </div>
                            <div className='trailer-text'>
                                <ReactMarkdown>{this.state.content.text}</ReactMarkdown>
                                <a href={this.state.content.buttonUrl} target='_blank' rel="noreferrer" className='button'>{this.state.content.buttonLabel}</a>
                            </div>
                            <script src="https://player.vimeo.com/api/player.js"></script>
                        </div>
                    }
                </div>
            </div>
        )
    }
}

export default Trailer;