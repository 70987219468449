import './../css/watch.css';
import axios from 'axios';
import React from 'react';

interface Props {
    locale: string,
    setPageLoaded: Function
}

interface State {
    content: any
}

class Watch extends React.Component<Props, State> {
 
    state: State = {
        content: null
    }

    componentDidMount() {
        this.getContent(this.props.locale);
    }

    getContent = (locale: string) => {
        axios
            .get(process.env.REACT_APP_STRAPI_URL + '/api/watch?populate=deep&locale=' + locale)
            .then((response: any) => { 
                this.setState({
                    content: response.data.data.attributes
                }, () => { this.props.setPageLoaded("watch") });
            })
            .catch((error: any) => 
            {
                if(locale !== 'en' && error.response && error.response.status === 404)
                {
                    console.log('Error: Could not find localized content > loading English');
                    this.getContent('en');
                }
                else
                {
                    console.log(error.message);
                }
            });
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
        if(prevProps.locale !== this.props.locale)
        {
            this.getContent(this.props.locale);
        }
    }

    getLocale = (language: string) => {
        if(language == 'ar')
            return 'ar-IQ';
        else
            return 'en-GB';
    }

    render() {
        return (
            <div id="watch" className="page yellow"> 
                <div className="content">
                    {this.state.content && 
                        <div>
                            <h1>{this.state.content.title}</h1>
                            <div id="watch-flex">
                                {this.state.content.physical &&
                                    <div className="watch-physical">
                                        <h2>{this.state.content.physical.title}</h2>
                                        <img src={process.env.REACT_APP_STRAPI_URL + this.state.content.physical.image.data.attributes.formats.small.url} alt="DVD" />
                                        {this.state.content.physical.description}
                                        {this.state.content.physical.buttonUrl &&
                                            <a className="button" href={this.state.content.physical.buttonUrl} target='_blank' rel='noreferrer'>{this.state.content.physical.buttonLabel}</a>
                                        }
                                    </div>
                                }
                                {this.state.content.videoOnDemand &&
                                    <div className="watch-physical">
                                        <h2>{this.state.content.videoOnDemand.title}</h2>
                                        <img src={process.env.REACT_APP_STRAPI_URL + this.state.content.videoOnDemand.image.data.attributes.formats.small.url} alt="VOD" />
                                        {this.state.content.videoOnDemand.description}
                                        {this.state.content.videoOnDemand.buttonUrl &&
                                            <a className="button" href={this.state.content.videoOnDemand.buttonUrl} target='_blank' rel='noreferrer'>{this.state.content.videoOnDemand.buttonLabel}</a>
                                        }
                                    </div>
                                }
                                <div className="watch-screenings">
                                    <h2>{this.state.content.screeningsTitle}</h2>
                                    {this.state.content.screenings ? this.state.content.screenings.map((screening: any) => 
                                        <div className="watch-screening" key={screening.id}>
                                            {screening.date &&
                                                <div className="watch-screening-date">
                                                    {screening.showMonthOnly === true ? new Date(screening.date).toLocaleDateString(this.getLocale(this.props.locale), { month: 'short', year: 'numeric' }) : new Date(screening.date).toLocaleDateString(this.getLocale(this.props.locale))}
                                                </div>
                                            }
                                            <div className="watch-screening-location">
                                                {!screening.url &&
                                                    screening.location
                                                }
                                                {screening.url &&
                                                    <a href={screening.url} target='_blank' rel='noreferrer'>{screening.location}</a>
                                                }
                                            </div>
                                        </div>
                                    ) : 
                                        <div className="watch-screening">
                                            <div className="watch-screening-location">{this.state.content.noScreeningsText}</div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        )
    }
}

export default Watch;